import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import CustomSelectBox from "../../common/CustomSelectBox/CustomSelectBox";
import RadioButtonWrapper from "../../common/RadioButtonsWrapper/RadioButtonWrapper";
import IntermediateCourses from "./Intermediate";
import DegreeCourses from "./Degree2years";
import AuthUser from "../../../../../api/Api";
import { useNavigate } from "react-router-dom";

const AddCollegeGeneralInfo = ({
  
  setEdit,
  updateCollegeProfileData,
  setValue,
  collegeId,
  nextStep
}) => {
  const navigate = useNavigate();
  const { http } = AuthUser();
  const GET_REGIONS_URL = "regions";
  const GET_DISTRICTS_URL = "districts";
  const GET_TOWNS_URL = "towns";
  const POST_COLLEGES_GENERAL = "colleges-general";

  const [CollegeRegions, setCollegeRegions] = useState([]);
  const [CollegeDistricts, setCollegeDistricts] = useState([]);
  const [CollegeTowns, setCollegeTowns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [collegeLevels] = useState([
    { name: "college_level", value: "Intermediate", label: "Intermediate" },
    { name: "college_level", value: "Degree", label: "Degree" },
  ]);

  const [collegeShifts] = useState([
    { value: "Morning", label: "Morning" },
    { value: "Evening", label: "Evening" },
  ]);

  const [collegeGenders] = useState([
    { value: "Boys", label: "Boys" },
    { value: "Girls", label: "Girls" },
    { value: "Co-Education", label: "Co-Education" },
  ]);

  const [collegeProfileData, setCollegeProfileData] = useState({
    college_region: "",
    college_district: "",
    college_town: "",
    college_location: "",
    college_name: "",
    college_year_of_establishment: "",
    college_shift: "",
    college_category: "",
    college_level: "",
    college_address: "",
    college_phone_no: "",
    college_email: "",
    college_intermediate_courses: [],
    college_degree_courses: [],
    college_program_type: "",
    college_program_offered: "",
  });

  const [collegeProfileErrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [renderIntermediateCourses, setRenderIntermediateCourses] =
    useState(false);

  const calculateFilledPercentage = () => {
    const totalFields = Object.keys(collegeProfileData).length;
    const filledFields = Object.values(collegeProfileData).filter(
      (value) => value !== ""
    ).length;
    return (filledFields / totalFields) * 100;
  };

  const handleChange = (e) => {
    // console.log("in handlechange");
    console.log(e.target);
    const { name, value, type, checked } = e.target;

    // console.log(name, value, type, checked);

    if (type === "checkbox") {
      let updatedValue;

      console.log(collegeProfileData);

      if (collegeProfileData.college_level === "Intermediate") {
        updatedValue = checked
          ? [...collegeProfileData.college_intermediate_courses, value]
          : collegeProfileData.college_intermediate_courses.filter(
              (course) => course !== value
            );

        setCollegeProfileData((prevData) => ({
          ...prevData,
          college_intermediate_courses: [
            ...prevData.college_intermediate_courses,
            value,
          ],
          college_degree_courses: null, // Reset degree courses
        }));
      } else if (collegeProfileData.college_level === "Degree") {
        updatedValue = checked
          ? [...collegeProfileData.college_degree_courses, value]
          : collegeProfileData.college_degree_courses.filter(
              (course) => course !== value
            );

        setCollegeProfileData((prevData) => ({
          ...prevData,
          college_degree_courses: updatedValue,
          college_intermediate_courses: null, // Reset intermediate courses
        }));
      }
    } else {
      if (name === "college_level") {
        setCollegeProfileData((prevData) => ({
          ...prevData,
          college_program_type: "",
          college_program_offered: "",
          college_degree_courses: [], // Reset degree courses
          college_intermediate_courses: [], // Reset intermediate courses
        }));
      }

      const updatedValue = type === "checkbox" ? checked : value;
      const finalValue =
        Array.isArray(updatedValue) && updatedValue.length === 0
          ? null
          : updatedValue;

      setCollegeProfileData((prevData) => ({
        ...prevData,
        [name]: finalValue,
      }));
    }
  };

  const fetchDataById = async (collegeId) => {
    try {
      const res = await http.get(`${POST_COLLEGES_GENERAL}/${collegeId}`);
      // console.log("API Response:", res);

      // console.log("Temp data");
      // console.log(tempData);
      if (res?.data?.data) {
        res.data.data["college_location"] =
          res.data.data["college_location"] === "Urban" ? "1" : "2";

        delete res.data.data["id"];
        delete res.data.data["created_at"];
        delete res.data.data["deleted_at"];
        delete res.data.data["updated_at"];
        // setCollegeGeneralInfo(res.data.data);
        setCollegeProfileData({
          ...res.data.data[0],
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataById(collegeId);
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_REGIONS_URL);
      const formattedData = response.data.regions.map((item) => ({
        value: item.region_name,
        label: item.region_name,
      }));
      setCollegeRegions(formattedData);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }

    try {
      const response = await http.get(GET_DISTRICTS_URL);
      const formattedData = response.data.data.map((item) => ({
        value: item.district_name,
        label: item.district_name,
      }));
      setCollegeDistricts(formattedData);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }

    try {
      const response = await http.get(GET_TOWNS_URL);
      const formattedData = response.data.data.map((item) => ({
        value: item.town_name,
        label: item.town_name,
      }));
      setCollegeTowns(formattedData);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      // Set buttonDisabled to true to disable the button during submission
      setButtonDisabled(true);
      setLoading(true);
  
      // Make a POST request to submit college general information
      const response = await http.post(`${POST_COLLEGES_GENERAL}`, collegeProfileData);
  
      // Show success message from the server response
      alert(response.data.message);
  
      // Reset any error state
      setError(null);
    } catch (error) {
      // Handle errors
      if (error.response && error.response.data && error.response.data.errors) {
        // Check if the errors object contains the specific field error
        const fieldError = error.response.data.errors.college_phone_no;
        if (fieldError) {
          alert(fieldError[0]); // Display the field error message
        } else {
          // If the specific field error is not available, show the general error message
          alert(error.response.data.message);
        }
      } else {
        // Show a generic error message for any other unexpected errors
        console.error("Error submitting data:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    } finally {
      nextStep();
      // Reset buttonDisabled to false after submission is completed
      setButtonDisabled(false);
      setLoading(false);
    }
  };
  
  

  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">General Information</h1>
        </div>

        <div className="p-4">
          <div className="flex space-x-4">
            <CustomSelectBox
              label="Region"
              name="college_region"
              value={collegeProfileData.college_region}
              options={CollegeRegions}
              onChange={handleChange}
            />

            <CustomSelectBox
              label="District"
              name="college_district"
              value={collegeProfileData.college_district}
              options={CollegeDistricts}
              onChange={handleChange}
            />

            <CustomSelectBox
              label="Taluka / Tehsil / Town"
              name="college_town"
              value={collegeProfileData.college_town}
              options={CollegeTowns}
              onChange={handleChange}
            />
          </div>

          <RadioButtonWrapper
            error={collegeProfileErrors.college_location}
            onChange={handleChange}
            rData={[
              {
                name: "college_location",
                value: 1,
                label: "Urban",
                checked:
                  collegeProfileData.college_location == "1" ? true : false,
              },
              {
                name: "college_location",
                value: 2,
                label: "Rural",
                checked:
                  collegeProfileData.college_location == "2" ? true : false,
              },
            ]}
          />

          <div className="text-left">
            <CustomInputBox
              type="text"
              label="Name of College"
              name="college_name"
              value={collegeProfileData.college_name}
              onChange={handleChange}
            />

            <CustomInputBox
              label="Year of Establishment"
              name="college_year_of_establishment"
              value={collegeProfileData.college_year_of_establishment}
              error={collegeProfileErrors.college_year_of_establishment}
              onChange={handleChange}
            />

            <CustomSelectBox
              label="Shift of College"
              name="college_shift"
              value={collegeProfileData.college_shift}
              options={collegeShifts}
              onChange={handleChange}
            />

            <CustomSelectBox
              label="Category"
              name="college_category"
              value={collegeProfileData.college_category}
              options={collegeGenders}
              onChange={handleChange}
            />

            <CustomSelectBox
              label="College Level"
              name="college_level"
              value={collegeProfileData.college_level}
              options={collegeLevels}
              onChange={handleChange}
            />

            {collegeProfileData.college_level === "Intermediate" ? (
              <IntermediateCourses
                collegeProfileData={collegeProfileData}
                handleChange={handleChange}
              />
            ) : collegeProfileData.college_level === "Degree" ? (
              <DegreeCourses
                collegeProfileData={collegeProfileData}
                handleChange={handleChange}
                setCollegeProfileData={setCollegeProfileData}
              />
            ) : null}

            <CustomInputBox
              label="Address of College"
              name="college_address"
              value={collegeProfileData.college_address}
              error={collegeProfileErrors.college_address}
              onChange={handleChange}
            />

            <CustomInputBox
              label="College Phone no"
              name="college_phone_no"
              value={collegeProfileData.college_phone_no}
              error={collegeProfileErrors.college_phone_no}
              onChange={handleChange}
            />

            <CustomInputBox
              label="College Email"
              name="college_email"
              value={collegeProfileData.college_email}
              error={collegeProfileErrors.college_email}
              onChange={handleChange}
            />
          </div>

          <div>
          <Button
  variant="contained"
  disabled={buttonDisabled}
  sx={{
    mt: 1,
    mr: 1,
    color: "white !important",
    backgroundColor: "#059669 !important",
  }}
  onClick={handleSubmit}
>
  {buttonDisabled ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress size={24} sx={{ marginRight: 1 }} />
      <span>Please wait...</span>
    </div>
  ) : (
    "Save & Next"
  )}
</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCollegeGeneralInfo;
